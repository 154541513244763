import React,{useState,useEffect} from 'react';


import axios from 'axios'


import { 
    Layout,
    Row,Col,
    Button
   } from 'antd';

import {history} from '../helper'
import AdminLayout from '../layouts/AdminLayout'

const { Content} = Layout;

const NoticeDetail =({location})=> {

    console.log('called NoticeDetail')

    const [status, setStatus]= useState(false) 
    const [item, setItem] = useState({});


    useEffect(()=>{

        getData()

    },[])


    const getData = async () =>{

        console.log("location", location)

        const params ={id:location.state.id}
        
        console.log("param", params)

        const {data} = await axios.post('api/notice/05_detail',params);


        setItem(data.result)


    }



    const gotoModify=()=>{

        history.push('/noticeModify', { id: item.id });

    }

    

    return(
      
        <AdminLayout menukey='2' pagetype="sub" setRstatus={setStatus}>
            
            <div className="notice_container">
       
                <Content className="site-layout-background" style={{ padding: 30, minHeight:'100vh' }}>
                
                <Row >
                    <Col xl={{span:24}} lg={{span:24, offset:0}} md={{span:6, offset:6}} sm={24} xs={24}>
                    
                    <div className="detailTitle">
                        Notice Detail  
                    </div>
                    </Col>
                 
                </Row>

                 <Row justify={status ? "center" : "start"}>
                    
                   <Col lg={8} md={24} sm={24} xs={24} className="itmetitle">
                       Title
                     </Col>
 
                 </Row>


                 <Row justify={status ? "center" : "start"}>
                     <Col lg={12} md={24} sm={24} xs={24}>
                       <div className="itemtext">
                         {item.title}
                      </div>    
                     </Col>
 
                 </Row>

                 <Row justify={status ? "center" : "start"}>
                    
                    <Col lg={8} md={24} sm={24} xs={24} className="itmetitle">
                        Reg. Date
                      </Col>
  
                  </Row>
 
 
                  <Row justify={status ? "center" : "start"}>
                      <Col lg={12} md={24} sm={24} xs={24}>
                        <div className="itemtext">
                          {item.createdAt}
                       </div>    
                      </Col>
  
                  </Row>

                  <Row justify={status ? "center" : "start"}>
                    
                    <Col lg={8} md={24} sm={24} xs={24} className="itmetitle">
                        Content
                      </Col>
  
                  </Row>

                 <Row justify={status ? "center" : "start"}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                     <div className="itembox" dangerouslySetInnerHTML={{__html:item.content}}>
                      
                     </div>
                    </Col>
                 
                 </Row>

                 <Row justify={status ? "center" : "start"}>
                 <Col xl={2} lg={12} md={12} sm={24} xs={24}>
                       
                       <Button type="primary" block onClick={gotoModify}>MODIFY</Button>
                       
                </Col>
                 </Row>
     
               </Content>
            
            </div>

            
            </AdminLayout>
    )
}


export default NoticeDetail;