import React, { useEffect} from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { history } from './helper'
import { alertAction } from './actions'
import { PrivateRoute}  from './component/PrivateRoute';

import { Login,NoticeList,NoticeDetail,NoticeModify,NoticeWrite,DirList,DirDetail,DirModify } from './container'



import './App.css'


const App =()=>{

    

    const alert = useSelector( state => state.alert);

    console.log("alert",alert)

    const dispatch = useDispatch();


     useEffect(() => {

         history.listen((location, action) => {

             dispatch(alertAction.clear());
         });
       
        dispatch(alertAction.clear());

    },[dispatch]); 



    return (
        <div>
           
           <Router history={ history }>
               <Switch>
                    <PrivateRoute exact path="/" component= {DirList} /> 

                   <PrivateRoute path="/noticeList" component= {NoticeList} />
                   <PrivateRoute path="/noticeDetail" component= {NoticeDetail} />
                   <PrivateRoute path="/noticeModify" component= {NoticeModify} />
                   <PrivateRoute path="/noticeWrite" component= {NoticeWrite} />
                   <PrivateRoute path="/dirList" component= {DirList} />
                   <PrivateRoute path="/dirDetail" component= {DirDetail} />
                   <PrivateRoute path="/dirModify" component= {DirModify} />
                   <Route path="/login" component= {Login} />

                   <Redirect from="*" to="/" /> 

               </Switch>
           </Router>

        </div>
    )


}

export default App;
