import { userConstant } from '../constants'
import { userService } from '../service'
import { history } from '../helper'


const login =function(username,password){

    return (dispatch) =>{

        dispatch({type:userConstant.LOGIN_REQUEST,user:username})

        userService.login(username,password).then( (data)=>{

            console.log('data', data)
            
            if(data.code === "1"){
           
                dispatch({type:userConstant.LOGIN_SUCCESS, user: data.user})

                console.log('call titckelist')

                history.push('/ticketlist')
           
            }else{

              dispatch({type:userConstant.LOGIN_FAILURE, error:'ID OR PASSWORD is not matched!!'})
           }
           
        },
        (error)=>{

            dispatch({type:userConstant.LOGIN_FAILURE,error:error.toString()})

        }
            
        )

    }

    

}


const logout= () => {

    userService.logout()

    return{type:userConstant.LOGOUT}
}


export const userAction={
    login,
    logout
}
