import React,{useState} from 'react';


import { 
    Layout,
    Row,Col,
    Tabs  
   } from 'antd';

  

import {history} from '../helper'
import AdminLayout from '../layouts/AdminLayout'
import FloorList from '../component/FloorList'

const { Content} = Layout;
const { TabPane } = Tabs;


const tab_list =[{id:'1',floor:'0', floorName:'B'},{id:'6',floor:'1', floorName:'1F'},{id:'19',floor:'2', floorName:'2F'},{id:'35',floor:'3', floorName:'3F'},{id:'48',floor:'4', floorName:'4F'}]

const DirList =({location})=> {

    window.scrollTo(0, 0);
    

    const [status, setStatus]= useState(false) 

    const [selectFloor, setFloor] = useState(location.state ? location.state.floor : 0);

    const handleGoback=()=>{

        history.push('/dirList')
  
    }
  
    window.history.pushState(null, '', location.href);
  
    window.onpopstate = () => {
      history.go(1);
      handleGoback();
    };
  

    const onChange = (activeKey)=>{

        console.log(activeKey)

        setFloor(activeKey)


    }

  

    return(
      
        <AdminLayout menukey='1' pagetype="main" setRstatus={setStatus}>
            
            <div className="notice_container">
 
       
                <Content className="site-layout-background" style={{ padding: 30, minHeight:'100%' }}>
               
                {/* 헤더 부분 */}
                <Row >
                    <Col xl={{span:24}} lg={{span:24, offset:0}} md={{span:24, offset:0}} sm={24} xs={24}>
                    
                    <div className="detailTitle">
                        Directory List
                    </div>
                    </Col>
                 
                </Row>

                {/* body */}
                <Row justify={status ? "center" : "start"}>
                
                <Col lg={23} md={23} sm={23} xs={23}>

                <Tabs defaultActiveKey={""+selectFloor} size="small"  type="card" onChange={onChange}>

                   
                    {
                        tab_list.map((item)=>{
                            return(
                                <TabPane tab={item.floorName} key={item.floor} index={item.id}>
                                  <FloorList floor={selectFloor}></FloorList>
                                </TabPane>
                            )
                        })
                    }

                    
                </Tabs>
                
                
                </Col>

                </Row>
                
               
     
               </Content>
            
            </div>

            
            </AdminLayout>
    )
}


export default DirList;