import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Layout, Row,Col } from 'antd';
import { useSelector } from 'react-redux';
import {history} from '../helper'

import { LoginOutlined,LeftOutlined } from '@ant-design/icons';

import SideMenu from '../component/menu'

import logo from '../assets/logo_txt_h50.png'

const { Header,  Sider, Content } = Layout;



const AdminLayout =({children,menukey, pagetype, setRstatus, goBack })=> {

    const user_state = useSelector( state => state.user);

    const [isBroken, setBroken] = useState(false)

    const borkenStatus = (broken)=>{

        console.log('status', broken);

        setRstatus(broken)
        setBroken(broken)

    }

    const back =()=>{

        if(goBack){
        
            goBack()
        
        }else{

            history.goBack();
            
        }
       
    }
   
        return (

       <div className="App">

        <Layout>
            
            <Sider 
                breakpoint="xl"
                collapsedWidth="0"
                onBreakpoint={broken => {
              
                    borkenStatus(broken)
  
                }}
                onCollapse={(collapsed, type) => {
                 //console.log(collapsed, type);
                }}

                style={{minHeight:'100vh'}}>
                     
                    <SideMenu menukey = {menukey} />

            </Sider>
            
            <Layout>
              <Header>

              <Row justify="center" align="top">
                    
                    <Col lg={18} md={22} sm={22} xs={22} className="d-flex"> 
                    
                    <div className="logo">
                        <img src= {logo} alt="logo"/>
                    </div>  
                    
                    <div className="main_header">Digital Signage</div>

                    <div className="main_tile"> {user_state.user.org_name}</div>
                    
                    <div className="m_logo">
                        <img src= {logo} alt="logo"/>
                    </div>
                    
                    </Col>
                    
                    <Col lg={5} md={1} sm={1} xs={1} style={{textAlign:'right'}}>
                        { pagetype === 'main' &&
                        <Link  to="/login" style={{color:'white'}}> 
                           <LoginOutlined />
                        </Link>
                        }
                         { pagetype === 'sub' &&
                            <>
                            <div onClick={back} className="mobile_only"><LeftOutlined /></div>
                            
                            <Link  to="/login" className="pc_only">
                            <span className="mr-2 clolor_white"><LoginOutlined /> </span>
                            </Link>
                           
                            </>
                        
                        
                        }

                    </Col>
                </Row>


              </Header>
              <Content>
                        
                {children}

                       
                           
            </Content>
             
            </Layout>
          </Layout>
    </div>
       
);
    
}

export default AdminLayout;