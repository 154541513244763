/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect, useState,useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Layout,Form,Input, Button, Checkbox } from 'antd';
import { userAction } from '../actions'
import './Login.css';

import {UserOutlined,LockOutlined} from '@ant-design/icons'; 

function Login() {

  const loggingIn = useSelector(state => state.user.loggingIn);
  const loggedIn = useSelector(state => state.user.loggedIn);

 
  console.log('loggedIn',loggedIn)
  console.log('loggingIn',loggingIn)

  //const [status, setStatus] = useState(false)
  
  const loginStatus = useRef(false)


  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => { 


    console.log('call useEffect')

    if(localStorage.username){

      form.setFieldsValue({      
        username:localStorage.username,
      });


    }

    console.log("call dispath logout")

    dispatch(userAction.logout()); 
    
 }, [dispatch]);



  

  const onFinish = async (value)=>{

    console.log("called onFinish")
    //setStatus(true);

    loginStatus.current = true;
 
    dispatch(userAction.login(value.username,value.password));

    
    if(value.remember){

      localStorage.username = value.username;
    
    }else{
      
      localStorage.username = ''
    }


  }

  return (
    <div className="App">
      <Layout>

        <div style={{display:'flex', flexDirection:'column', justifyContent:'center', height:'100vh'}}>

        
          <div className="loginWraper">
            
            <div className="title mobile_container">
               <h3 className="mt-5">Boranet</h3>
               <h3 className="mb-5">Directory Service</h3>
               
            </div>

            <div className="title pc_container">
               
               <h3 className="mb-5">Boranet Directory Service</h3>
             
            </div>

            
            <div className="loginFrm">

            <Form form={form}
                  name="basic"
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  className="login-form">
                 <Form.Item
                   name="username"
                   rules={[
                     {
                       required: true,
                       message: 'Please input your Username!',
                     },
                   ]}
                 >
                   <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                 </Form.Item>
                 <Form.Item
                   name="password"
                   rules={[
                     {
                       required: true,
                       message: 'Please input your Password!',
                     },
                   ]}
                 >
                   <Input
                     prefix={<LockOutlined className="site-form-item-icon" />}
                     type="password"
                     placeholder="Password"
                   />
                 </Form.Item>
                 {!loggedIn && loginStatus.current &&
                    <div style={{color:'red'}}>*Login infomation is not correct!!</div>
                 }
              
                   <Form.Item name="remember" valuePropName="checked" noStyle>
                     <Checkbox>Remember me</Checkbox>
                   </Form.Item>
                
                 
                 <Form.Item>
              
                   <Button type="primary" htmlType="submit" className="login-form-button mt-3">
                     Log in
                   </Button>
      
                </Form.Item>
              
              </Form>
             
              
              </div>
          </div>
       
         
        

        </div>
      
      </Layout>
    
    </div>
  );
}

export default Login;
