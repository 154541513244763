import React,{useState, useEffect } from 'react';
import axios from 'axios'
import {history} from '../helper'

import { 
    Row,
    Col,
    List
   } from 'antd';

import { DoubleRightOutlined } from '@ant-design/icons'; 

const FloorList =({floor})=>{

  


    const [list, setList] = useState([])

    useEffect(()=>{

        const param={floor: floor}

        axios.post('/api/directory/01_list',param).then(res=>{

            console.log(res.data.record_list)

            setList(res.data.record_list)
        })


    },[floor])
    
    
    const gotoDetail=(item)=>{

        console.log(item)
        
        history.push('/dirModify',{id:item.id})

    }


    return(
        <Row>
            <Col lg={12} md={24} sm={24} xs={24}>

            <List
                className="demo-loadmore-list"
                itemLayout="horizontal"
                 dataSource={list}
                 renderItem={item => (
                <List.Item>
                  
                  <List.Item.Meta
                    title={
                    <div onClick={()=>gotoDetail(item)} className="d-flex">
                        <div className="text-bold ml-1 mr-5" style={{width:'20px'}}>{item.roomNumber}</div>
                        <div dangerouslySetInnerHTML={{__html:item.company}}></div>
                    </div>
                    }
                    />

                     <div onClick={()=>gotoDetail(item)}><DoubleRightOutlined /></div> 
                
                </List.Item>
            )}
            />

            
            </Col>

            
        </Row>

    )
}

export default FloorList