import axios from 'axios';

const login = async (username,password)=>{

    const {data} = await axios.post('/api/login/01_login',{username,password})

    console.log("login data", data);
    
    sessionStorage.setItem('user',JSON.stringify(data.user))

    return data

}

const logout=function(){

    console.log('called logout and remove sesstion stroage')

    sessionStorage.removeItem('user');
}

export const userService={
    login,
    logout
}