import { combineReducers } from 'redux'

import user from './user.reducers'

import alter from './alert.reducers'

const rootReducer = combineReducers({
    
    user,
    alter,
})

export default rootReducer;