import React,{useState,useEffect,useRef} from 'react';
import {  useSelector } from 'react-redux';

import moment from 'moment'
import axios from 'axios'


import { 
    Layout,
    Row,Col,Table,
    PageHeader,
    Button,
    DatePicker,
    Select 
   } from 'antd';

import { EditOutlined } from '@ant-design/icons';  

import {history} from '../helper'
import AdminLayout from '../layouts/AdminLayout'

const { Content} = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const start_dt = moment().subtract('month', 6).format(dateFormat)
console.log(start_dt)


const NoticeList =({location})=> {

   console.log('called NoticeList')

    const [startdt,setStartDt]=useState(start_dt)
    const [enddt,setEndDt]=useState(moment().add('hours',10).format('YYYY-MM-DD HH:mm:ss'))  //db에 데이터가 utc+0로 저장되기 때문에 미국 동부 표준시 보정
    const [listdata,setListData] = useState([]);
    const [pagenation,setpagenation] = useState({})
    const [loading,setLoading] = useState(false);
    const [pageData,setPageData] = useState({page:1,page_size:10})

    window.scrollTo(0, 0);

  
    const handleGoback=()=>{

      history.push('/noticeList')

  }

  window.history.pushState(null, '', location.href);

  window.onpopstate = () => {
    history.go(1);
    handleGoback();
  };


    useEffect(()=>{

        getData({pageSize:10,page:1,startdt,enddt})
  
      },[])

      const setStatus=()=>{
        
      }

    const getData= async (params)=>{

        params.customer_id = '91111';

        console.log("param",params)

        const {data} = await axios.post('/api/notice/04_list',params)
        console.log('data==>',data);
        const _pagenation = {...pagenation }

        _pagenation.total = data.total_record;
        console.log("data_list",data.record_list)

        setListData(data.record_list)
        setLoading(false)
        setpagenation(_pagenation)

       

    }


    const dtHandelr=(dates, dateStrings)=>{

        setStartDt(dateStrings[0])
        setEndDt(dateStrings[1])

    }

   

    const searchData = async ()=>{
       
      

        const params={
          enddt:enddt,
          startdt:startdt
        
        }

        console.log('search param',params)

        await getData(params)

    }

   

    const handleTableChange=async (pagination, filters, sorter)=>{

        console.log('pagination',pagination)
        console.log('filters',filters)
        console.log('sorter',sorter)

        const pager = { pagenation };
        pager.current = pagenation.current;
        setpagenation(pager);

        setPageData({page:pagenation.current,page_size:pagenation.pageSize})

        await getData({
          pageSize: pagenation.pageSize,
          page: pagenation.current,
          sortField: sorter.field,
          sortOrder: sorter.order,
          start_dt:startdt,
          end_dt:enddt,
          ...filters,
        });

    }

    const dateYYYYMMDD = (text) =>{
      
      return text ? moment(text).format('YYYY-MM-DD'):'-'
      

    }

    const gotoDetail=(e,record) =>{

      console.log(record);

       history.push('/noticeDetail', { id: record.id });

    }

    const columns = [
        {
          title: 'Num',
          key: 'index',
          render:(text, record, index) => {
             return index+((pageData.page-1)*pageData.page_size)+1
          }
        },
        
        {
          title: 'Title',
          dataIndex: 'title',
          key: 'title',
          width: '60%',
          render:(text, record,index) => {

            return(
              <span onClick={ (e)=>gotoDetail(e,record)} style={{color:'blue', cursor:'pointer',textDecoration:'underline'}}>{text}</span>
            )

          }

        },
       
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',

            render:(text) =>{
                const _status = text === 0 ? 'In Active' : 'Active';

                return(<span>{_status}</span>)
            }

        },
        
        {
              title: 'Reg. Date',
              dataIndex: 'createdAt',
              key: 'createdAt',
              responsive: ['lg'],
              render: (text) =>{
                return dateYYYYMMDD(text)
              },
            },
  
         
        ];

        const gotoWriet =()=>{

          history.push('/noticeWrite')

        }

    return(
      
        <AdminLayout menukey='2' pagetype="main" setRstatus={setStatus}>
            
            <div className="notice_container">
       
                <Content style={{ padding: 30, minHeight:'100%' }}>

                    {/* 헤더 부분 */}
                <Row>
                    <Col xl={{span:24}} lg={{span:24, offset:0}} md={{span:24, offset:0}} sm={24} xs={24}>
                    
                    <div className="detailTitle">
                        Notice List
                    </div>
                    </Col>
                 
                </Row>
               

                <Row gutter={[{ xs: 4, sm: 16, md: 24, lg: 32 }, 24]} justify="center" align="middle">

                  <Col lg={8} md={12} sm={24}>

                 
                    <RangePicker
                            defaultValue={[moment(startdt, dateFormat), moment(enddt, dateFormat)]}
                            onChange={dtHandelr}
                            style={{width:'100%'}}
                    /> 
                  
                  </Col>

                
                </Row>
               
               
                <Row gutter={[4, 24]} justify="center" align="middle">

                  <Col lg={8} md={8} sm={24} xs={24}>
                  <Button type="primary" block onClick={searchData}>Search</Button>
                  </Col>

                
                </Row>
                <Row justify="end" className="mt-3">
                  <Col lg={1} md={1} sm={3} xs={3}>
                  <div style={{fontSize:'18px', fontWeight:'bold', color:'red'}} onClick={gotoWriet}><EditOutlined /></div>
                  </Col> 

                </Row>

                <Row>
                    <Col span={24}>
                    <Table columns={columns}
                        rowKey={record => record.id}
                        dataSource={listdata} 
                        pagination={pagenation}
                        loading={loading}
                        onChange={handleTableChange}
                        size="middle"
                        style={{ marginTop: 20 }}
        
                    />
                    </Col>
                    
                </Row>
                 
           
               </Content>
            
            </div>

            
            </AdminLayout>
    )
}


export default NoticeList;