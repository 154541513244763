import React,{useState,useEffect} from 'react';

import axios from 'axios'


import { 
    Layout,
    Row,
    Col,
    Input,
    Radio,
    Button

   } from 'antd';

  

import {history} from '../helper'
import AdminLayout from '../layouts/AdminLayout'

const { Content} = Layout;
const { TextArea } = Input;

const DirModify =({location})=> {

    const [status, setStatus]= useState(false) 
    const [room, setRoom]= useState({})
    const [company,setCompany]= useState(null) 
    const [isVacant, setVacant] = useState(false)
    const [showmsg, setShowMessage] = useState(false)
    const [errMsg, setErrMsg]= useState(null);
   
 
    if(location.state){

        sessionStorage.setItem("id",location.state.id);
    }
   
    const handleGoback=()=>{

        history.push('/dirList',{floor:room.floor})
    }

  
    window.history.pushState(null, '', location.href);

    
      /** 아이폰 처리 루틴 */

    (function(ua){
        ua = ua || navigator.userAgent;
        ua = ua.toLowerCase();
        if( /safari/.test(ua) && !!window.chrome ){
          // not safari
          window.onpopstate = () => {
           
            history.go(1);
           
            handleGoback();
          };
        }else{
          // if safari
          setTimeout(function(){
            window.onpopstate = () => {
                history.go(1);
                handleGoback();
              };
          }, 500);
        }
      })(navigator.userAgent);

     
    
    useEffect(()=>{

       
        getData()

  

    },[])


    const getData = async () =>{

        const id = location.state ? location.state.id : sessionStorage.getItem('id');
        
        const params ={id:id}

        console.log("param", params)

        const {data} =  await axios.post('/api/directory/02_detail',params);

            const _tmp =  data.result
           
            _tmp.company = _tmp.company.replace(/(<br>|<br\/>|<br \/>)/g, '\r\n')

            setCompany(_tmp.company)

            setRoom(_tmp)
            setVacant(data.result.isVacant)


    }

    const onChange =(e)=>{

        setVacant(e.target.value)

        if(e.target.value === true){
           
            setCompany('')

        }

    }

    const textAreaHandler = (e)=>{

        console.log(e.target.value)
        setCompany(e.target.value)
    }


    const save = async ()=>{

        let _company = "Vacant";

        if(company){

            _company = company.replace(/(\n|\r\n)/g, '<br>')
        }

       

        const param={id:room.id, company:_company, isVacant}

        const {data} = await axios.post('/api/directory/03_update', param);

        if(data.result > 0){

            history.push('/dirList',{floor:room.floor})

        }else{

            setShowMessage(true)
            setErrMsg('Update failed. Please do it again in a while')

        }
    
    
    }



    return(
      
        <AdminLayout menukey='1' pagetype="sub" setRstatus={setStatus} goBack={handleGoback}>
            
            <div className="notice_container">
       
                <Content className="site-layout-background" style={{ padding: 30, minHeight:'100vh' }}>
                
                    {/* 헤더 부분 */}
                <Row >
                    <Col xl={{span:24}} lg={{span:24, offset:0}} md={{span:24, offset:0}} sm={24} xs={24}>
                    
                    <div className="detailTitle">
                        Directory Detail
                    </div>
                    </Col>
                 
                </Row>

                
                {/* body */}
                <Row justify={status ? "center" : "start"}>
                
                <Col lg={23} md={23} sm={23} xs={23}>

                    <Row justify={status ? "center" : "start"} className="mt-2 mb-2">
                        
                        <Col lg={8} md={24} sm={24} xs={24} className="itmetitle">
                            SUITE : {room.roomNumber}
                        </Col>
    
                    </Row>
{/* 
                    <Row justify={status ? "center" : "start"} className="mb-1">
                        
                        <Col lg={8} md={24} sm={24} xs={24} className="itmetitle">
                            Status
                        </Col>
    
                    </Row> */}

                    <Row justify={status ? "center" : "start"} className="mt-2 mb-2">

                        <Col lg={1} md={2} sm={2} xs={5} className="itmetitle">
                                Status
                        </Col>

                        
                        <Col lg={23} md={22} sm={22} xs={19} className="itmetitle">
                                
                            <Radio.Group onChange={onChange} value={isVacant}>
                                <Radio value={true}>Vacant</Radio>
                                <Radio value={false}>Occupied </Radio>
                            </Radio.Group>
                                       
                                    
                        </Col>
        
                    </Row>

                    <Row justify={status ? "center" : "start"} className="mt-2 mb-1">
                        
                        <Col lg={8} md={24} sm={24} xs={24} className="itmetitle">
                            Company
                        </Col>
    
                    </Row>

                    <Row justify={status ? "center" : "start"}>
                        
                        <Col lg={8} md={24} sm={24} xs={24}>

                         <TextArea autoSize={{minRows:3, maxRows:6}} showCount maxLength={200} onChange={textAreaHandler} value={company}/>  
                            
                        </Col>
    
                    </Row>

                    <Row justify={status ? "center" : "start"} className="mt-3">
                            <Col xl={3} lg={12} md={12} sm={24} xs={24}>
                                
                                <Button type="primary"  block onClick={save}>SAVE</Button>
                                
                            </Col>
                        </Row>

                        { showmsg &&
                        <Row justify={status ? "center" : "start"} className="mt-3">
                            <Col xl={3} lg={12} md={12} sm={24} xs={24}>
                                
                                <div className="text-danger">{errMsg}</div>
                                
                            </Col>
                        </Row>
                        }

                  


                </Col>

                </Row>

              
                
               
     
               </Content>
            
            </div>

            
            </AdminLayout>
    )
}


export default DirModify;