import React,{useState,useEffect,useRef} from 'react';
import {  useSelector } from 'react-redux';

import axios from 'axios'


import { 
    Layout,
    Row,Col,
    Button,
    Input,
    Radio,
    Form
   
   } from 'antd';

  

import {history} from '../helper'
import AdminLayout from '../layouts/AdminLayout'

const { Content} = Layout;




const NoticeWrite =({location})=> {

    
    const user = useSelector(state => state.user.user);



    const [status, setStatus]= useState(false) 

    const [showmsg, setShowMessage] = useState(false)
    const [errMsg, setErrMsg]= useState(null);

    const [form] = Form.useForm();

    const onFinish =async (value)=>{

        console.log(value)

        const content = value.content.replace(/(\n|\r\n)/g, '<br>');

        const param={...value, content:content, writer:user.username, customer_id:user.customer_id}

        const {data} = await axios.post('api/notice/01_write',param);
        
        console.log(data)

        if(data.result > 0){

            history.push('/noticeList')

        }else{


            setShowMessage(true)
            setErrMsg('Update failed. Please do it again in a while')
            


        }


    }

    return(
      
        <AdminLayout menukey='2' pagetype="sub" setRstatus={setStatus}>
            
            <div className="notice_container">
       
                <Content className="site-layout-background" style={{ padding: 30, minHeight:'100vh' }}>
                <Row>
                    <Col xl={{span:24}} lg={{span:24, offset:0}} md={{span:6, offset:0}} sm={24} xs={24}>
                    
                    <div className="detailTitle">
                        Notice Write  
                    </div>
                    </Col>
                 
                </Row>

                <Row justify={status ? "center" : "start"}>
                    
                    <Col lg={12} md={24} sm={24} xs={24}>

                    <Form form={form} id="noticeFrm" onFinish={onFinish}>
                        <Row justify={status ? "center" : "start"}>
                        
                            <Col lg={24} md={24} sm={24} xs={24} className="itmetitle mb-2">
                                TITLE <small className="text-secondary">( less 40)</small>
                            </Col>
    
                        </Row>
 
 
                        <Row justify={status ? "center" : "start"}>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item name="title"
                                 rules={[
                                    {
                                      required: true,
                                      message: 'Please input Title!',
                                    },
                                  ]}
                                >
                                    <Input 
                                    maxLength={40} 
                                    />
                                </Form.Item>
                            </Col>
        
                        </Row>

                       
 

                        <Row justify={status ? "center" : "start"}>
                        
                            <Col lg={24} md={24} sm={24} xs={24} className="itmetitle mb-2">
                                CONTENT <small className="text-secondary">( less 200)</small>
                            </Col>
    
                        </Row>
 
 
                        <Row justify={status ? "center" : "start"}>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item name="content"
                                 rules={[
                                    {
                                      required: true,
                                      message: 'Please input Content!',
                                    },
                                  ]}
                                >
                                
                                <Input.TextArea 
                                autoSize={{minRows:3, maxRows:10}} 
                                showCount 
                                maxLength={200}
                               
                                />  
                                       
                                    </Form.Item>
                            </Col>
        
                        </Row>

                        <Row justify={status ? "center" : "start"} className="mt-3">
                            <Col xl={3} lg={12} md={12} sm={24} xs={24}>
                                
                                <Button type="primary" htmlType="submit" block>SAVE</Button>
                                
                            </Col>
                        </Row>

                        { showmsg &&
                        <Row justify={status ? "center" : "start"} className="mt-3">
                            <Col xl={3} lg={12} md={12} sm={24} xs={24}>
                                
                                <div className="text-danger">{errMsg}</div>
                                
                            </Col>
                        </Row>
                        }


                    </Form>
                       
                    </Col>
  
                  </Row>
                
               
     
               </Content>
            
            </div>

            
            </AdminLayout>
    )
}


export default NoticeWrite;