import { userConstant } from '../constants';

let initalState={};
    
if(sessionStorage.getItem('user')){

    initalState = {logined:true,user:JSON.parse(sessionStorage.getItem('user'))} ;
}

const User=(state=initalState, action)=>{

    console.log("action", action)

  
    switch (action.type) {
        case userConstant.LOGIN_REQUEST:
            
           return{
               loggingIn:true,
               user:action.user
           }

        case userConstant.LOGIN_SUCCESS:
            
            return{
                ...state,
                loggedIn: true,
                user: action.user
            }

        case userConstant.LOGIN_FAILURE:

            return{
                loggedIn:false,
                error:action.error
            }
        
        case userConstant.LOGOUT:

                return{
                    loggedIn:false,
                    user:''
                }
    
        default:
            return state;
    }



}




export default User;