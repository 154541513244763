/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState,useEffect,useRef} from 'react';
import {  useSelector } from 'react-redux';

import axios from 'axios'


import { 
    Layout,
    Row,Col,
    Button,
    Input,
    Radio,
    Form
   
   } from 'antd';

  

import {history} from '../helper'
import AdminLayout from '../layouts/AdminLayout'

const { Content} = Layout;




const NoticeModify =({location})=> {

    
    const user = useSelector(state => state.user.user);

    const [status, setStatus]= useState(false) 

    const [showmsg, setShowMessage] = useState(false)
    const [errMsg, setErrMsg]= useState(null);

    const [form] = Form.useForm();

    const id = useRef();

    const handleGoback=()=>{

        history.push('/noticeList')

    }

    window.history.pushState(null, '', location.href);

     /** 아이폰 처리 루틴 */

     (function(ua){
        ua = ua || navigator.userAgent;
        ua = ua.toLowerCase();
        if( /safari/.test(ua) && !!window.chrome ){
          // not safari
          window.onpopstate = () => {
           
            history.go(1);
           
            handleGoback();
          };
        }else{
          // if safari
          setTimeout(function(){
            window.onpopstate = () => {
                history.go(1);
                handleGoback();
              };
          }, 500);
        }
      })(navigator.userAgent);


   

    useEffect(() => {

        const params ={id:location.state.id}
        console.log("param", params)

        axios.post('api/notice/05_detail',params).then(res=>{

            const item = res.data.result;

            console.log("item",item)

            id.current = item.id

            form.setFieldsValue({
            
                title:item.title,
                status:item.status,
                content:item.content.replace(/(<br>|<br\/>|<br \/>)/g, '\r\n')
    
              });


        })
    

      
    }, [])

    
    
    
    const onFinish =async (value)=>{

        console.log("called on finesh",value)

        const content = value.content.replace(/(\n|\r\n)/g, '<br>');

        const param={...value, content:content, id: id.current}

        const {data} = await axios.post('api/notice/02_update',param);
        
        console.log(data)

        if(data.result > 0){

            history.push('/noticeList')

        }else{


            setShowMessage(true)
            setErrMsg('Update failed. Please do it again in a while')
            


        }


    }

    const deleteNotice = async (event)=>{
        
        event.preventDefault()

        

        const param={id: id.current}

        const {data} = await axios.post('api/notice/03_delete',param);
        
       
        if(data.result > 0){

            history.push('/noticeList')

        }else{


            setShowMessage(true)
            setErrMsg('Delete failed. Please do it again in a while')
            


        }


    }

    return(
      
        <AdminLayout menukey='2' pagetype="sub" setRstatus={setStatus}>
            
            <div className="notice_container">
       
                <Content className="site-layout-background" style={{ padding: 30, minHeight:'100vh' }}>
                <Row>
                    <Col xl={{span:24}} lg={{span:24, offset:0}} md={{span:6, offset:0}} sm={24} xs={24}>
                    
                    <div className="detailTitle">
                         Notice Detail 
                    </div>
                    </Col>
                 
                </Row>

                <Row justify={status ? "center" : "start"}>
                    
                    <Col lg={12} md={24} sm={24} xs={24}>

                    <Form form={form} id="noticeFrm" onFinish={onFinish}>
                        <Row justify={status ? "center" : "start"}>
                        
                            <Col lg={24} md={24} sm={24} xs={24} className="itmetitle mb-2">
                                TITLE <small className="text-secondary">( Less than 40 characters )</small>
                            </Col>
    
                        </Row>
 
 
                        <Row justify={status ? "center" : "start"}>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item name="title">
                                    <Input maxLength={40} />
                                </Form.Item>
                            </Col>
        
                        </Row>

                        <Row justify={status ? "center" : "start"}>
                        
                            <Col lg={24} md={24} sm={24} xs={24} className="itmetitle mb-2">
                                STATUS
                            </Col>
    
                        </Row>
 
 
                        <Row justify={status ? "center" : "start"}>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item name="status">
                                        <Radio.Group>
                                            <Radio value={0}>Inactive</Radio>
                                            <Radio value={1}>Active</Radio>
                                        </Radio.Group>
                                       
                                    </Form.Item>
                            </Col>
        
                        </Row>

                        <Row justify={status ? "center" : "start"}>
                        
                            <Col lg={24} md={24} sm={24} xs={24} className="itmetitle mb-2">
                                CONTENT <small className="text-secondary">(  Less than 200 characters )</small>
                            </Col>
    
                        </Row>
 
 
                        <Row justify={status ? "center" : "start"}>
                            <Col lg={24} md={24} sm={24} xs={24}>
                                <Form.Item name="content">
                                
                                <Input.TextArea autoSize={{minRows:3, maxRows:10}} showCount maxLength={200}/>  
                                       
                                    </Form.Item>
                            </Col>
        
                        </Row>

                        <Row justify={status ? "center" : "start"} className="mt-3">
                            <Col xl={3} lg={12} md={12} sm={24} xs={24}>
                                
                                <Button type="primary" htmlType="submit" block>SAVE</Button>
                                
                            </Col>
                        </Row>

                        <Row justify={status ? "center" : "start"} className="mt-3">
                            <Col xl={3} lg={12} md={12} sm={24} xs={24}>
                                
                                <button className="btn btn-danger btn-sm btn-block" onClick={deleteNotice}>DELETE</button>
                                
                            </Col>
                        </Row>

                        { showmsg &&
                        <Row justify={status ? "center" : "start"} className="mt-3">
                            <Col xl={3} lg={12} md={12} sm={24} xs={24}>
                                
                                <div className="text-danger">{errMsg}</div>
                                
                            </Col>
                        </Row>
                        }


                    </Form>
                       
                    </Col>
  
                  </Row>
                
               
     
               </Content>
            
            </div>

            
            </AdminLayout>
    )
}


export default NoticeModify;