import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import { createLogger } from "redux-logger";
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from '../reducers';

const middleware =[thunkMiddleware]
//const reduxLogger = createLogger(); //추가

export const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(...middleware) 
    )
   
)