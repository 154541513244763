import React,{useState,useEffect,useRef} from 'react';
import {  useSelector } from 'react-redux';

import moment from 'moment'
import axios from 'axios'


import { 
    Layout,
    Row,Col,Table,
    PageHeader,
    Button,
    DatePicker,
    Select 
   } from 'antd';

  

import {history} from '../helper'
import AdminLayout from '../layouts/AdminLayout'

const { Content} = Layout;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const start_dt = moment().subtract('month', 6).format(dateFormat)
console.log(start_dt)


const DirDetail =()=> {

    return(
      
        <AdminLayout menukey='1'>
            
            <div className="notice_container">
       
                <Content className="site-layout-background" style={{ padding: 30, minHeight:'100vh' }}>
                
                DirList
     
               </Content>
            
            </div>

            
            </AdminLayout>
    )
}


export default DirDetail;