import React from 'react'
import { Menu } from 'antd';
import { } from '@ant-design/icons';
import { Link } from 'react-router-dom';


const SideMenu = ({menukey})=>{


    return (

    <div style={{marginTop:'72px'}}>

        <Menu  defaultSelectedKeys={[menukey]} mode="inline" theme="dark">

            <Menu.Item key="1">
                <Link to="/dirList" >DIRECTORY</Link>
               
            </Menu.Item>

            <Menu.Item key="2">
                <Link to="/noticeList" >NOTICE</Link>
            
            </Menu.Item>


        </Menu>

    </div>


    )
}


export default SideMenu;